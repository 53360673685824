import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "AppInsights";
import { parse } from "flatted";
import "swiper/scss";
import "swiper/scss/pagination";
import { UserProvider } from "context/user";
import "../styles/fonts.scss";
import "../styles/global.scss";

export default function MyApp({ Component, pageProps }) {
  const page = pageProps.safeJson ? parse(pageProps.safeJson) : null;
  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(window.navigator.platform) ||
      // iPad on iOS 13 detection
      (window.navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  const isIos = typeof window !== "undefined" && iOS();
  if (
    typeof window !== "undefined" &&
    page?.pageContent?.component === "pdfAsset" &&
    isIos
  ) {
    window.location.href = `https:/${page?.pageContent.pdf.fields.file.url}`;
  }

  return page?.pageContent?.component === "pdfAsset" ? (
    isIos ? (
      <div className="pdfWaitScreen"></div>
    ) : (
      <iframe
        src={page?.pageContent.pdf.fields.file.url}
        width="100%"
        style={{ height: "100vh" }}
      ></iframe>
    )
  ) : (
    <AppInsightsContext.Provider value={reactPlugin}>
      <UserProvider>
        <Component {...pageProps} />
      </UserProvider>
    </AppInsightsContext.Provider>
  );
}
